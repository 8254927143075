<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <!-- <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='sticky'>
        <a-select v-model="form.sticky" placeholder="活动类型" style="width: 250px">
          <a-select-option value="">
            活动类型
          </a-select-option>
          <a-select-option value="0">
            庆功宴
          </a-select-option>
          <a-select-option value="1">
            新人启航
          </a-select-option>
          <a-select-option value="2">
            新增
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='masterName'>
        <a-input v-model='form.masterName' style="width: 350px" placeholder="负责人" />
      </a-form-model-item>
      <a-form-model-item prop='status'>
        <a-select v-model="form.status" placeholder="状态" style="width: 250px">
          <a-select-option value="">
            状态
          </a-select-option>
          <a-select-option :value="0">
            筹备中
          </a-select-option>
          <a-select-option :value="1">
            进行中
          </a-select-option>
          <a-select-option :value="2">
            已结束
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model> -->
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>
      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='list' slot-scope='text'>
          <template>
            <div style="display: flex;">
              <div @click="previewVisible = true; previewImage = item.url;" class="image_list" v-for="(item, index) in text" :key="index">
                <template v-if="isVideoUrl(item.url)">
                  <video class="image_detail" :src="item.url"></video>
                  <img class="play_image" :src="playImg" >
                </template>
                <img v-else class="image_detail" :src="item.url" >
              </div>
            </div>
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a @click='initialDeliver1(record)'>修改</a>
              <a @click='initialDeliver2(record)'>授权</a>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>

    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <video style="width: 100%" v-if="isVideoUrl(previewImage)" :src="previewImage" controls autoplay></video>
      <img v-else alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>

    <a-modal title='记录修改' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-form-model-item label='记录内容' prop='recordContent'>
          <a-textarea :auto-size='true' v-model='deliver1.form.recordContent' />
        </a-form-model-item>
        <a-form-model-item label='图片/视频' prop='storeReward'>
          <a-upload
            list-type="picture-card"
            :file-list="deliver1.form.list"
            :customRequest='handleListUpload'
            :remove="handleListRemove"
          >
            <div v-if="deliver1.form.list && deliver1.form.list.length < 9">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                图片或视频
              </div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='授权' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver2' :confirmLoading='deliver2.loading'>
      <a-form-model ref='form2' :model='deliver2.form' :rules='deliver2.rules' :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>
        <a-form-model-item label='授权手机号' prop='mobile'>
          <a-input v-model='deliver2.form.mobile' placeholder="授权多个手机号用,隔开，如：13800138000,13800138001" />
        </a-form-model-item>
        <a-form-model-item label='授权结束时间' prop='authTime'>
          <a-date-picker :valueFormat="createFormatType" v-model="deliver2.form.authTime" showTime placeholder="选择日期时间"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { queryMemberRecord, putMemberRecord, deleteMemberRecord, putMemberRecordAuth } from '@/api/record'
import { isVideoUrl } from '@/utils/util'
import client from '@/config/oss.config.js'

const columns = [
  {
    width: 150,
    title: '记录人',
    dataIndex: 'nickname',
    scopedSlots: { customRender: 'nickname' }
  },
  {
    width: 200,
    title: '记录内容',
    dataIndex: 'recordContent',
    scopedSlots: { customRender: 'recordContent' }
  },
  {
    width: 200,
    title: '图片视频',
    dataIndex: 'list',
    scopedSlots: { customRender: 'list' }
  },
  {
    width: 150,
    title: '记录时间',
    dataIndex: 'recordTime',
    scopedSlots: { customRender: 'recordTime' }
  },
  {
    width: 150,
    title: '授权用户',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    width: 150,
    title: '授权结束时间',
    dataIndex: 'authTime',
    scopedSlots: { customRender: 'authTime' }
  },
  {
    width: 100,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const createFormatType = 'YYYY-MM-DD HH:mm:ss';

export default {
  name: 'MemberRecord',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      createFormatType,
      playImg: require('@/assets/play.jpg'),
      previewVisible: false,
      previewImage: "",
      // 筛选表单
      form: {},
      loading: false,
      dashboard: {}, //订单统计数据
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      loadData: parameter => {
        return queryMemberRecord(Object.assign(parameter, this.queryParam, this.form))
          .then(datum => {
            return datum
          })
      },
      deliver1: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {
          recordContent: [
            { required: true, message: '请填写记录内容', trigger: 'change' },
          ],
        },
      },
      deliver2: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {
          mobile: [
            { required: true, message: '请填写手机号', trigger: 'change' },
          ],
          authTime: [
            { required: true, message: '请填写授权结束时间', trigger: 'change' },
          ],
        },
      },
    }
  },
  created() {},
  methods: {
    isVideoUrl,
    handleReset() {
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    handleDelete(record) {
      deleteMemberRecord({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    async handleListUpload(event) {
      let result = await client.put('record/' + event.file.name, event.file)
      this.deliver1.form.list.push({ 
        url: result.url,
        uid: 0 - (this.deliver1.form.list.length + 1),
        name: result.url,
        status: 'done', 
      })
    },
    handleListRemove(event) {
      try {
        let index = this.deliver1.form.list.findIndex(item => item.url == event.url);
        this.deliver1.form.list.splice(index, 1);
        return true;
      } catch (error) {
        this.$message.warning('操作失败')
        return false
      }
    },
    initialDeliver1(record) {
      this.deliver1.form = Object.assign({}, record);
      for (let i = 0; i < this.deliver1.form.list.length; i++) {
        const item = this.deliver1.form.list[i];
        item.uid = 0 - (i + 1);
        item.name = item.id;
        item.status = 'done';
      }
      this.deliver1.visible = true
      this.deliver1.loading = false
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        if (result) {
          for (let i = 0; i < this.deliver1.form.list.length; i++) {
            const item = this.deliver1.form.list[i];
            item.sort = i;
          }
          this.deliver1.loading = true;
          putMemberRecord(this.deliver1.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver1.visible = false
            this.deliver1.loading = false;
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    initialDeliver2(record) {
      this.deliver2.form = Object.assign({}, record);
      this.deliver2.loading = false
      this.deliver2.visible = true
    },
    handleDeliver2() {
      this.$refs.form2.validate((result) => {
        console.log(result)
        if (result) {
          this.deliver2.loading = true;
          putMemberRecordAuth(this.deliver2.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver2.visible = false
            this.deliver2.loading = false;
          }).catch(() => {
            this.deliver2.loading = false;
          })
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.image_list {
  width: 80px;
  height: 80px;
  margin: 0 10px 10px 0;
  object-fit: cover;
  position: relative;
  .image_detail {
    height: 100%;
    width: 100%;
  }
  .play_image {
    position: absolute;
    width: 30px;
    height: 30px;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
  }
}
</style>

