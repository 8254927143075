import request from '@/utils/request'

export function queryMemberRecord(parameter) {
  return request({
    url: '/member/record/page',
    method: 'post',
    data: parameter
  })
}

export function putMemberRecord(parameter) {
  return request({
    url: '/member/record/update',
    method: 'post',
    data: parameter
  })
}

export function putMemberRecordAuth(parameter) {
  return request({
    url: '/member/record/auth',
    method: 'post',
    data: parameter
  })
}

export function deleteMemberRecord(parameter) {
  return request({
    url: '/member/record/delete/' + parameter.id,
    method: 'DELETE',
  })
}